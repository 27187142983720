import Vue from 'vue'
export const mainDomain = 'onlinemodule.ru';
export const crmSubdomain = 'crm.'+mainDomain;
export const profileSubdomain = 'profile.'+mainDomain;
export const mediaSubdomain = 'media.'+mainDomain;
export const server = 'https://service.'+mainDomain // localhost
export const apiEndpoint = {
  user: `${server}/api/v1/user`,
  logout: `${server}/api/v1/logout`,
  weather: `https://api.openweathermap.org/data/2.5/weather?id=491882&appid=6275dfeb2a8f905ab08ef15962750fbb&lang=ru&units=metric`,
  time: `https://worldtimeapi.org/api/timezone/Europe/Kirov`,
  classes: `${server}/api/v1/info/classes`,
  subjects: `${server}/api/v1/info/subjects`,
  groups: `${server}/api/v1/info/groups`,
  available: `${server}/api/v1/available`
};
export let refDate = new Date();
export const weatherIcons = {
  '01': 'mdi-weather-sunny',
  '02': 'mdi-weather-partly-cloudy',
  '03': 'mdi-weather-cloudy',
  '04': 'mdi-weather-partly-rainy',
  '05': 'mdi-weather-rainy',
  '06': 'mdi-weather-pouring',
  '07': 'mdi-weather-lightning',
  '08': 'mdi-weather-snowy-heavy',
  '09': 'mdi-weather-tornado'
};
export const goto = (uri = "") => window.location.assign(uri);
export const weatherUpdateInterval = 900000;
export const EventBus = new Vue()
export const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
export const addOrdinal = (n, p = {one: '', two: 'а', few: 'а', many: 'ов'} ) => `${p[new Intl.PluralRules('ru').select(n)]}`;
export const urlRegex = /(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/gi;
export const wrapUrl = str => str.split(' ').map(w=>w.replace(urlRegex, '<a href="$&">$&</a>')).join(' ');
export const isOverflown = (element) => element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
export const generateString = length => Array(length).fill('').map(() => Math.random().toString(36).charAt(2)).join('');
export const generateBoolean = () => Math.random() >= 0.5;
export const FEED_UPDATE_INTERVAL = 60000;
export const TASKS_UPDATE_INTERVAL = 60000;
export const SOLVES_UPDATE_INTERVAL = 60000;
export const getExt = fileName => fileName.split('.').pop();
export const pluck = (objs, property) => objs.map(obj => obj[property]);
export const unique = arr => [...new Set(arr)];
export const weekAgo = (date = new Date()) => generateDateArr(-7, date);
export const dateToString = (e = new Date()) => e.toISOString().slice(0, 10);
export const dateArrayToString = d => d.map(dateToString);
export const dateConvert = d => `${padDate(d.getDate())}-${padDate(d.getMonth()+1)}-${padDate(d.getFullYear())} ${padDate(d.getHours())}:${padDate(d.getMinutes())}:${padDate(d.getSeconds())}`;
export const padDate = d => `${d}`.padStart(2, '0');
export const flat = (arr) => arr.reduce((a, b) => (Array.isArray(b) ? [...a, ...flat(b)] : [...a, b]), []);
export const compareDates = (f, s) => {
  let a = toDate(f), b = toDate(s);
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
}
// If value positive => f > s; If value is 0 => f == s; If value is negative f < s;
export const compareDatesFull = (f = new Date(), s = new Date()) => f.getTime() - s.getTime();
export const dateStringToISO = d => dateToString(new Date(d));
export const generateDate = (a, b = new Date()) => new Date(b.valueOf() + 1000 * 60 * 60 * 24 * a);
export const yesterday = (date = new Date()) => generateDate(-1, date);
export const tomorrow = (date = new Date()) => generateDate(1, date);
export const generateDateArr = (a,b = new Date()) => {
    let arr = [];
    for(let i = 0; i <= Math.abs(a); ++i) {
        arr[i] = b;
        b = a < 0 ? yesterday(b) : tomorrow(b);
    }
    return arr;
}
export const deepObjectComparing = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);
export const shallowCopy = obj => obj instanceof Array ? [...obj] : {...obj};
export const formatDate = (date) => date.split('-').reverse().join('-');
export const getDateMonth = (date = new Date()) => `${padDate(date.getDate())}.${padDate(date.getMonth()+1)}`
export const getDate = (d = new Date()) => d instanceof Date ? Date.parse(`${dateToString(d)} 0:0:0`) : Date.parse(dateToString(toDate(d)));
export const sum = arr => arr.reduce((a, b) => a + b, 0);
export const isObjEmpty = obj => JSON.stringify(obj) === '{}';
export const getIntersection = (a, b) => [...new Set(a)].filter(v => b.includes(v));
export const toDate = (a = new Date()) => (a instanceof Date) ? a : new Date(a.length < 11 ? `${a} 0:0:0` : a);
export const nl2br = str => str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
export const toMiniRFC3339DateStr = (str = "") => str.slice(0, 10)
export const toMiniRFC3339Date = (d = new Date()) => toMiniRFC3339DateStr(d.toISOString())
export const getTimeFromDate = (d = new Date()) => `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
export const isArrEqual = (a, b) => JSON.stringify(a.sort()) === JSON.stringify(b.sort());
export const fillArray = (arr, val, start = 0, end = 0) => {
  if (start <= end) return []
  for(let i = start; i<end; ++i) {
    arr.push(val)
  }
  return arr;
}

export const classTypes = {
  primary: "Начальная школа",
  secondary: "Среднее звено",
  high: "Старшее звено",
  network: "Сетевой класс"
}
