<script>
export default {
  name: "UpdateClassesTeacher",
  props: {
    skippable: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    items: {
      get() {
        return this.$store.state.classes.map(c => ({text: c.name, value: c.id}))
      }
    }
  },
  data: () => ({
    isLoading: true,
    val: [],
  }),
  methods: {
    save() {
      this.$emit("save", this.val.map(cid => this.$store.state.classes.find(c => c.id === cid)).filter(Boolean));
    }
  },
  created() {
    if (this.$store.state.classes.length !== 0) {
      this.isLoading = false;
      return;
    }
    this.$store.dispatch('getClasses').then(r => {
      this.$nextTick(() => {
        this.isLoading = !r;
      });
    })
  }
}
</script>

<template>
  <v-card :loading="isLoading">
    <v-card-title>Обновление данных</v-card-title>
    <v-card-subtitle>Укажите классы, в которых Вы преподаёте</v-card-subtitle>
    <v-card-text>
      <v-select outlined :items="items" v-model="val" label="Класс(-ы)" multiple/>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" @click="save" :disabled="val.length === 0 && !skippable">{{ val.length === 0 && skippable ? "Пропустить" : "Далее" }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>

</style>