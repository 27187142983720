<template>
  <v-toolbar max-height="64px" color="primary">
    <img src="@/assets/logowh.png" alt="Онлайн.Модуль" class="module--logo"/>
    <v-spacer/>
    <links-bar />
    <v-spacer/>
    <v-container class="user--tooltip width-unset">
      <div class="user-tooltip">
        <div class="user--info">
          <div class="user--name">
            <span class="user-name secondary--text">{{ displayName }}</span>
          </div>
          <div class="user--additional">
            <div class="__spacer"></div>
            <span class="user-additional">
              <span class="user-classes secondary--text">{{ classes }}</span>
              <v-spacer/>
            </span>
          </div>
        </div>
        <div class="user--profile">
          <Menu/>
        </div>
      </div>
      
    </v-container>
  </v-toolbar>
</template>

<script>
import LinksBar from '../../components/TopBar/Links.vue'
import Menu from "./Menu";

export default {
  name: 'top-bar',
  components: {
    Menu,
    LinksBar,
  },
  computed: {
    displayName: {
      get() {
        return this.$store.state.user.displayname;
      }
    },
    classes: {
      get(){
        if(!this.$store.state.user.classes) return '[ ]';
        let classes = '[ ';
        let cl = this.$store.state.user.classes.map(e=>e.name);
        if(cl.length > 3) {
          classes += cl.slice(0, 3).join(', ');
          classes += '... ]';
        } else if (cl.length === 1) {
          classes = cl.toString()
        } else {
          classes += cl.join(', ');
          classes += ' ]';
        }
        return classes.toUpperCase();
      }
    },
  }
}
</script>

<style scoped>
  .module--logo {
    max-height: 56px;
    margin-right: 5px;
  }
  .user--tooltip {
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
  .user-tooltip {
    display: flex;
  }
  .user--profile {
    display: flex;
  }
  .user-additional {
    display: flex;
    justify-content: space-between;
  }
  .__spacer {
    width: 22px;
  }
  .user--info {
    margin-right: 12px;
  }
  .user--name {
    font-size: .9rem;
  }
  .user-classes {
    font-size: .8rem;
    align-self: center;
  }
</style>
<style>
.width-unset {
  width: unset !important;
}
</style>
