<template>
  <div class="user-profile">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="secondary" size="36" v-on="on" v-bind="attrs">
          <v-icon medium color="primary">mdi-account-outline</v-icon>
        </v-avatar>
      </template>
      <v-list>
        <v-list-item @click="goto(`https://${mainDomain}/account`)">
          Профиль
        </v-list-item>
        <v-list-item @click="logout">
          Выход
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {goto, mainDomain} from "@/common";
export default {
  name: "Menu",
  data: () => ({
    mainDomain
  }),
  methods: {
    goto,
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.goto(`https://${mainDomain}`);
      });
    }
  }
}
</script>

<style scoped>
  .user-profile {
    display: flex;
    align-self: center;
  }
</style>