<template>
  <v-container style="padding: 0" class="width-unset">
    <v-btn text @click="$router.push('/')" color="secondary">
      Сервисы
    </v-btn>
    <v-btn text @click="goto(`https://${mainDomain}/`)" color="secondary">
      Задания
    </v-btn>
    <v-btn text @click="goto(`https://${mainDomain}/solves`)" color="secondary">
      Решения
    </v-btn>
    <v-btn text @click="goto(`https://${mainDomain}/marks/journal`)" color="secondary">
      Журнал
    </v-btn>
    <v-btn text @click="goto(`https://${mainDomain}/marks/new`)" color="secondary" v-if="role != 'student'">
      Поставить оценку
    </v-btn>
    <v-btn text @click="goto(`https://${mainDomain}/schedule`)" color="secondary">
      Расписание
    </v-btn>
  </v-container>
</template>

<script>
import { mainDomain, goto } from '@/common'
export default {
  name:'links-bar',
  computed: {
    role: {
      get() {
        return this.$store.state.user.role
      }
    }
  },
  data: () => ({
    mainDomain
  }),
  methods: {
    goto
  }
}
</script>
