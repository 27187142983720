import axios from "@/plugins/axios";
import {apiEndpoint} from "@/common";

export default {
  namespaced: true,
  state: {
    name: '',
    surname: '',
    displayname: '',
    role: '',
    id: -1,
    flags: 0,
    classes: [],
    subjects: [],
    patronymic: '',
    password: '',
    isUpdated: true,
    isAdmin: false,
    isEmulationNeeded: false
  },
  mutations: {
    setUser(state, user) {
      state.id = user.id;
      state.name = user.name;
      state.surname = user.surname;
      state.patronymic = user.patronymic;
      state.role = user.role;
      state.displayname = user.displayName;
      state.classes = user.classes;
      state.subjects = user.subjects;
      state.isEmulationNeeded = false;
      state.isAdmin = user.isAdmin || false;
      state.flags = user.flags || 0;
      state.isUpdated = user.updated;
    },
    clearUser(state) {
      state.id = -1;
      state.name = '';
      state.surname = '';
      state.patronymic = '';
      state.password = '';
      state.email = '';
      state.classes = [];
      state.subjects = [];
      state.role = '';
      state.displayname = '';
      state.flags = 0;
      state.isAdmin = false;
      state.isUpdated = true;
    }
  },
  actions: {
    async getUser(ctx) {
      let res = await axios.get(apiEndpoint.user);
      if (res.status === 200) {
        ctx.commit('setUser', res.data);
        return true
      } else {
        if (res.status === 401) {
          if (window.location.pathname !== "/signup" && window.location.pathname !== "/signin" && window.location.pathname !== "/") {
            console.log('Tried to access protected path wo auth.')
            window.location.replace('/signup');
            return false
          }
        }
      }
    },

    async logout(ctx) {
      let res = await axios.get(apiEndpoint.logout);
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return false
      }
      ctx.commit('clearUser');
      return true
    },
    async patchUser(ctx, patchInfo) {
      let res = await axios.patch(apiEndpoint.user, {...patchInfo, id: ctx.state.id, surname: ctx.state.surname, name: ctx.state.name, patronymic: ctx.state.patronymic});
      if (res.status !== 200) {
        ctx.commit('showError', res.data);
        return false;
      }
      return await ctx.dispatch('getUser');
    }
  },
  getters: {
    isLoggedIn(state){
      return state.id != -1;
    }
  }

}