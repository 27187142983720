import axios from '../../plugins/axios'
import {apiEndpoint} from "../../common";

export default {
  namespaced: true,
  state: {
    classes: [],
    subjects: [],
    eActivities: [],
    sCourses: []
  },
  mutations: {
    setAvailable(state, av) {
      state.classes = av.classes;
      state.subjects = av.subjects;
      state.eActivities = av.eActivities;
      state.sCourses = av.sCourses;
    }
  },
  actions: {
    async getAvailable(ctx) {
      let res = await axios.get(apiEndpoint.available);
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return false;
      }
      ctx.commit('setAvailable', res.data);
      return true;
    },
    async putAvailable(ctx) {
      let res = await axios.put(apiEndpoint.available, ctx.state);
      if (res.status !== 200) {
        ctx.commit('showError', res.data);
        return false;
      }
      return true;
    }
  }
}