<template>
  <v-card :loading="isLoading">
    <v-card-title>Обновление данных</v-card-title>
    <v-card-subtitle>Укажите предметы, которые Вы преподаёте</v-card-subtitle>
    <v-card-text>
      <template v-for="(o, idx) in subjects">
        <v-card outlined class="update--class" :key="`${o['class'].name}-${idx}`">
          <v-card-subtitle>{{ o['class'].name }}:</v-card-subtitle>
          <v-card-text>
            <div class="class--subject" v-for="(sbj, sIdx) in subjectsVal[o['class'].name]" :key="`${o['class'].name}-${sbj.id}-${sIdx}`">
              <v-select
                  outlined
                  label="Предмет"
                  :items="subjectItems[o['class'].name]"
                  :value="sbj.id"
                  @input="(val) => saveSubject(o['class'].name, idx, sIdx, val)"
              />
              <template v-if="subjects[idx].subjects.find(s => s.id === sbj.id) && subjects[idx].subjects.find(s => s.id === sbj.id).group">
                <v-select
                    outlined
                    label="Группы"
                    :items="subjects[idx].subjects.find(s => s.id === sbj.id).group"
                    v-model="subjectsVal[o['class'].name][sIdx].group"
                    multiple
                />
              </template>
            </div>
          </v-card-text>
          <div class="class--subject-add">
            <v-btn icon @click="addSubject(o['class'].name)">
              <v-icon color="primary">mdi-plus-circle</v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" @click="save" >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {flat, shallowCopy} from "../../common";

export default {
  name: "UpdateSubjects",
  props: {
    classes: {
      type: Array,
      required: true
    }
  },
  computed: {
    subjects: {
      get() {
        return this.$store.state.subjects;
      }
    },
    subjectItems: {
      get() {
        let sbjs = {};
        this.subjects.forEach(o => {
          o.subjects.forEach(sbj => {
            if (!sbjs[o['class'].name]) sbjs[o['class'].name] = [];
            sbjs[o['class'].name].push({text: sbj.name, value: sbj.id});
          });
        });
        return sbjs;
      }
    }
  },
  data: () => ({
    isLoading: true,
    subjectsVal: {},
    tmpSubjects: [],
    tmpGroups: []
  }),
  methods: {
    saveSubject(className, classIdx, sbjIdx, val) {
      let sbj = shallowCopy(this.subjects[classIdx].subjects.find(s => s.id === val));
      if (sbj.group) {
        sbj.group = [];
      }
      this.$set(this.subjectsVal[className], sbjIdx, sbj);
    },
    addSubject(className) {
      if(!this.subjectsVal[className]) this.$set(this.subjectsVal, className, []);
      if(this.subjectsVal[className].some(sbj => sbj.id === -1)) return;
      this.subjectsVal[className].push({id: -1});
    },
    save(){
      let sbjs = Object.keys(this.subjectsVal).map(className => {
        let c = this.classes.find(cl => cl.name === className);
        if (!c) {
          console.warn("can not find class:", className);
          return null;
        }
        return this.subjectsVal[className].map(sbj => {
          if(sbj.id === -1) return null;
          sbj['class'] = [c];
          return sbj;
        });
      });
      this.$emit('save', flat(sbjs).filter(Boolean));
    }
  },
  created() {
    this.$store.dispatch('getSubjects', {classes: this.classes}).then(r => {
      this.$nextTick(() => {
        this.isLoading = !r;
      });
    });
  }
}
</script>

<style scoped>
.class--subject-add{
  width: 100%;
  display: flex;
  justify-content: center;
}
.class--subject {
  width: 100%;
}
</style>