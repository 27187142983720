<template>
  <v-app>
    <v-dialog :value="error.show" max-width="400" persistent>
      <error :title="error.title" :content="error.message" :fatal="error.fatal"></error>
    </v-dialog>
    <update-user-dialog v-if="!user.isUpdated"/>
    <template v-if="isTopBarNeeded" >
      <top-bar class="app--header" />
      <navigation-drawer class="app--header__mobile" />
    </template>
    <v-main id="main">
      <div class="content">
        <router-view/>
      </div>
    </v-main>
    <div class="page-loading--wrap" v-if="isPageLoading">
      <div class="page-loading">
        <img src="assets/loading-u-200.svg" alt="Загрузка..." />
      </div>
    </div>
  </v-app>
</template>
<script>
import TopBar from '@/components/TopBar/Bar';
import NavigationDrawer from "@/components/TopBar/NavigationDrawer";
import Error from "@/components/Error";
// eslint-disable-next-line no-unused-vars
import {mainDomain, crmSubdomain, mediaSubdomain, profileSubdomain} from "./common";
import UpdateUserDialog from "./components/UpdateUser/UpdateUserDialog.vue";

export default {
  name: 'App',
  components: {
    UpdateUserDialog,
    Error,
    TopBar,
    NavigationDrawer
  },
  computed: {
    isTopBarNeeded: {
      get () {
        return this.$store.state.user.id !== -1
      }
    },
    isPageLoading: {
      get(){
        return this.$store.state.isPageLoading;
      }
    },
    error: {
      get() {
        return this.$store.state.error;
      }
    },
    user: {
      get() {
        return this.$store.state.user
      }
    }
  },
  data() {
    return {
      loaded: [false],
      auth: false,
      crmSubdomain: crmSubdomain,
      mediaSubdomain,
      profileSubdomain,
      mainDomain
    }
  },
  created () {
    this.$store.dispatch('user/getUser').then(() => {
      if(!this.$store.getters['user/isLoggedIn']) {
        console.log('Tried to access protected path wo auth.')
        window.location.replace(`https://${mainDomain}/signin`);
      } else {
        this.$set(this.loaded, 0, true);
        this.auth = true;
      }
    });
  },
  watch: {
    loaded: {
      deep: true,
      handler() {
        if (this.loaded.every(l=>l === true)){
          this.$store.commit('setLoading', false);
        }
      }
    }
  }
};
</script>
<style>
.app--header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
</style>
<style scoped>
#main {
  max-width: 800px;
  align-self: center;
  margin-top: 64px;
  width: 100%;
}

.page-loading--wrap{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app--header__mobile {
  display: none;
}
.content {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}
.dboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  place-items: center;
  gap: 10px;
}

.dboard--btn {
  margin: 5px;
}

</style>

<style>
h1 {
  text-align: center;
}
.display-flex {
  display: flex;
}
.w-100 {
  width: 100%;
}
@media only screen and (max-width: 1180px) {
  .app--header {
    display: none;
  }

  .app--header__mobile {
    display: block !important;
  }

  #main {
    margin-top: 0 !important;
  }
}
@media only screen and (max-width: 550px) {
  .marks--student__wrapper.v-treeview-node .v-treeview-node__level-mark {
    border: 0 !important;
  }
}
@media only screen and (min-width: 800px) {
  .tasks-row > * {
    width: 100%
  }
}
</style>