<template>
  <div>
    <v-toolbar color="primary" collapse absolute>
      <v-btn @click="drawer = true" icon>
        <v-icon color="white">mdi-menu</v-icon>
      </v-btn>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" app color="primary" temporary>
      <v-list-item>
        <v-list-item-content>
          <img src="@/assets/logowh.png" alt="Онлайн.Модуль" class="module--logo"/>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="white--text --title">
            {{ displayName }}
          </v-list-item-title>
          <v-list-item-subtitle class="white--text --subtitle">
            {{ classes }}
            <v-spacer></v-spacer>
            <v-btn icon class="user--edit" color="secondary" height="22px" width="22px" @click="goto(`https://${mainDomain}/account`)">
              <v-icon small>fa-pencil</v-icon>
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav class="white--text">
        <v-list-item @click="goto(`https://service.${mainDomain}`)">
          <v-list-item-icon>
            <v-icon color="white">mdi-view-grid</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text v-navigation-link">Сервисы</v-list-item-title>
          </v-list-item-content>
        </v-list-item><v-list-item @click="goto(`https://${mainDomain}/tasks`)">
          <v-list-item-icon>
            <v-icon color="white">mdi-format-list-text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text v-navigation-link">Задания</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="goto(`https://${mainDomain}/solves`)">
          <v-list-item-icon>
            <v-icon color="white">mdi-format-list-checks</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text v-navigation-link">Решения</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="goto(`https://${mainDomain}/schedule`)">
          <v-list-item-icon>
            <v-icon color="white">mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text v-navigation-link">Расписание</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon color="white">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text v-navigation-link">Выход</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>

</template>

<script>
import { mainDomain, goto } from '@/common'

  export default {
    name: 'NavigationDrawer',
    computed: {
      displayName: {
        get() {
          return this.$store.state.user.displayname;
        }
      },
      role: {
        get() {
          return this.$store.state.user.role;
        }
      },
      classes: {
        get(){
          if(!this.$store.state.user.classes) return '[ ]';
          let classes = '[ ';
          let cl = this.$store.state.user.classes.map(e=>e.name);
          if(cl.length > 3) {
            classes += cl.slice(0, 3).join(', ');
            classes += '... ]';
          } else if (cl.length === 1) {
            classes = cl.toString()
          } else {
            classes += cl.join(', ');
            classes += ' ]';
          }
          return classes.toUpperCase();
        }
      },
    },
    data: () => ({
      drawer: false,
      mainDomain
    }),
    methods: {
      goto,
      logout() {
        this.$store.dispatch('user/logout').then(() => this.goto(`https://${mainDomain}`));
      }
    }
  };
</script>

<style scoped>
  .--subtitle {
    display: flex;
  }
  .--title {
    text-align: justify;
  }
  .v-navigation-link {
    color: #fff;
    font-size: .9rem !important;
  }
  .module--logo {
    width: 120px;
  }
</style>