<script>
import UpdateClassesStudent from "./UpdateClassesStudent.vue";
import UpdateClassesTeacher from "./UpdateClassesTeacher.vue";
import UpdateMasteredClasses from "./UpdateMasteredClasses.vue";
import UpdateSubjects from "./UpdateSubjects.vue";

export default {
  name: "UpdateUserDialog",
  components: {UpdateSubjects, UpdateMasteredClasses, UpdateClassesTeacher, UpdateClassesStudent},
  computed: {
    user: {
      get() {
        return this.$store.state.user;
      }
    }
  },
  data: () => ({
    state: 0,
    tClasses: [],
    tMClasses: [],
    tSubjects: [],
  }),
  methods: {
    updateStudent(cl) {
      this.$store.dispatch('user/patchUser', {classes: cl}).then(r => {
        if (r) {
          this.$store.dispatch('user/getUser')
        }
      })
    },
    setTeacherClasses(cl) {
      this.tClasses = cl;
      if (cl.length === 0) {
        this.save();
        return;
      }
      this.state++;
    },
    setMasteredClasses(mcl) {
      this.tMClasses = mcl;
      this.state++;
    },
    setTeacherSubjects(sbj) {
      this.tSubjects = sbj;

      this.save();
    },
    save() {
      this.$store.dispatch('user/patchUser', {classes: this.tClasses, mClasses: this.tMClasses, subjects: this.tSubjects}).then(r => {
        if (r) {
          this.$store.dispatch('user/getUser');
        }
      });
    }
  }
}
</script>

<template>
  <v-dialog :value="true" max-width="800" persistent>
    <update-classes-student v-if="user.flags&0x40" @save="updateStudent"/>
    <template v-else-if="user.flags&0x10">
      <update-classes-teacher v-if="state === 0" :skippable="false" @save="setTeacherClasses"/>
      <update-subjects v-else-if="state === 1" :classes="tClasses" @save="setTeacherSubjects"/>
    </template>
    <template v-else-if="user.flags&0x20">
      <update-mastered-classes v-if="state === 0" @save="setMasteredClasses"/>
      <update-classes-teacher v-else-if="state === 1" :skippable="true" @save="setTeacherClasses"/>
      <update-subjects v-else-if="state === 2" :classes="tClasses" @save="setTeacherSubjects"/>
    </template>
  </v-dialog>
</template>

<style scoped>

</style>