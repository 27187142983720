<script>
export default {
  name: "UpdateClassesStudent",
  computed: {
    items: {
      get(){
        return this.$store.state.classes.map(c => ({text: c.name, value: c.id}))
      }
    }
  },
  data: () => ({
    isLoading: true,
    val: 0
  }),
  methods: {
    save() {
      let r = [this.$store.state.classes.find(c => c.id === this.val)];
      if (!r) {
        this.$store.commit('showError', {title: "Ошибка в данных", content: "Указан несуществующий класс"})
        return;
      }
      this.$emit('save', r);
    }
  },
  created() {
    if (this.$store.state.classes.length !== 0) {
      this.isLoading = false;
      return;
    }
    this.$store.dispatch('getClasses').then(r => {
      this.$nextTick(() => {
        this.isLoading = !r;
      });
    })
  }
}
</script>

<template>
  <v-card :loading="isLoading">
    <v-card-title>Обновление данных</v-card-title>
    <v-card-subtitle>Укажите класс, в котором Вы учитесь</v-card-subtitle>
    <v-card-text>
      <v-select outlined :items="items" v-model="val" label="Класс"/>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" @click="save">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>

</style>